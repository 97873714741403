import './TagsInfoTable.css';

import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { StyledTable } from '../../../../../styled/table/StyledTable';
import {
  BoxTag,
  ConnectorTag,
  ITagInfo,
  TagDirection,
  TagValueType,
} from '../../../../../types/tag.types';
import { SendCommandForm } from '../SendCommandForm/SendCommandForm';
import { TagsInfoTableValueColumn } from './TagsInfoTableValueColumn';
import { TagsTimestamp } from './TagsTimestamp';

const MESSAGES = defineMessages({
  buttonText: {
    id: 'tag_info_table.buttonText',
    defaultMessage: 'Send command',
  },
  categoryTableHeading: {
    id: 'tag_info_table.categoryTableHeading',
    defaultMessage: 'Category',
  },
  tagDescriptionTableHeading: {
    id: 'tag_info_table.tag_descriptionTableHeading',
    defaultMessage: 'Tag Description',
  },
  tagNameTableHeading: {
    id: 'tag_info_table.tag_nameTableHeading',
    defaultMessage: 'Tag Name',
  },
  tagIdTableHeading: {
    id: 'tag_info_table.tag_idTableHeading',
    defaultMessage: 'Tag ID',
  },
  signalTypeTableHeading: {
    id: 'tag_info_table.signalTypeTableHeading',
    defaultMessage: 'Signal Type',
  },
  valueTypeTableHeading: {
    id: 'tag_info_table.valueTypeTableHeading',
    defaultMessage: 'Value Type',
  },
  valueTableHeading: {
    id: 'tag_info_table.valueTableHeading',
    defaultMessage: 'Value',
  },
  lastUpdateTableHeading: {
    id: 'tag_info_table.lastUpdateTableHeading',
    defaultMessage: 'Last Update',
  },
});

interface IProps {
  assetId: string;
  tagInfoList: ITagInfo[];
}

interface ITagInfoTableData extends ITagInfo {
  isTableHeading: boolean;
}

export const TagsInfoTable = ({ assetId, tagInfoList }: IProps) => {
  const intl = useIntl();

  // filter arrays based on their category and insert an extra row that
  // can be used to display a colored heading in the table

  const tableData = tagInfoList
    .map((tag) => tag.category)
    .filter((v, i, a) => a.indexOf(v) === i)
    .flatMap((category) => {
      const filteredTagInfoList = tagInfoList.filter(
        (tag) => tag.category === category,
      );
      filteredTagInfoList.unshift({
        isTableHeading: true,
        category,
      } as ITagInfoTableData);
      return filteredTagInfoList;
    }) as ITagInfoTableData[];

  const columns: ColumnsType<ITagInfoTableData> = [
    {
      title: intl.formatMessage(MESSAGES.categoryTableHeading),
      dataIndex: 'category',
      key: 'category',
      className: 'no-empty-cell-placeholder',
      render: (category: string, record: ITagInfoTableData) =>
        record.isTableHeading ? category : '',
    },
    {
      title: intl.formatMessage(MESSAGES.tagNameTableHeading),
      dataIndex: 'tag',
      className: 'no-empty-cell-placeholder',
      key: 'tag',
    },
    {
      title: intl.formatMessage(MESSAGES.tagDescriptionTableHeading),
      dataIndex: 'description',
      className: 'no-empty-cell-placeholder',
      key: 'description',
    },
    {
      title: intl.formatMessage(MESSAGES.tagIdTableHeading),
      dataIndex: 'tagId',
      className: 'no-empty-cell-placeholder',
      key: 'tagId',
    },
    {
      title: intl.formatMessage(MESSAGES.signalTypeTableHeading),
      dataIndex: 'signalType',
      className: 'no-empty-cell-placeholder',
      key: 'signalType',
    },
    {
      title: intl.formatMessage(MESSAGES.valueTypeTableHeading),
      dataIndex: 'valueType',
      className: 'no-empty-cell-placeholder',
      key: 'valueType',
    },
    {
      title: intl.formatMessage(MESSAGES.valueTableHeading),
      dataIndex: 'valueType',
      className: 'no-empty-cell-placeholder',
      render: (valueType: TagValueType, record: ITagInfoTableData) => (
        <TagsInfoTableValueColumn
          valueType={valueType}
          tag={record.tag as BoxTag | ConnectorTag}
          assetId={assetId}
        />
      ),
    },
    {
      dataIndex: 'direction',
      key: 'commandForm',
      className: 'no-empty-cell-placeholder',
      render: (direction: TagDirection, record: ITagInfoTableData) =>
        direction === TagDirection.OUT &&
        !record.isTableHeading && (
          <SendCommandForm
            assetId={assetId}
            tagName={record.tag}
            tagValueType={record.valueType}
          />
        ),
    },
    {
      title: intl.formatMessage(MESSAGES.lastUpdateTableHeading),
      dataIndex: 'updatedAt',
      className: 'no-empty-cell-placeholder',
      key: 'updatedAt',
      render: (updatedAt: string, record: ITagInfoTableData) => {
        return (
          <TagsTimestamp
            updatedAt={updatedAt}
            record={record}
            boxId={assetId}
            tag={record.tag}
          />
        );
      },
    },
  ];

  return (
    <StyledTable
      columns={columns}
      dataSource={tableData}
      pagination={false}
      rowClassName={(record: ITagInfoTableData) =>
        record.isTableHeading ? 'tag-category-table-heading ' : ''
      }
      rowKey={(record: ITagInfoTableData) => record.tag || record.category}
    />
  );
};
