import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../../../store/selectors/tags.selectors';
import { BoxTag, ConnectorTag } from '../../../../../types/tag.types';

interface IProps {
  updatedAt: string | number;
  record: any;
  boxId: string;
  tag: BoxTag | ConnectorTag;
}

export const TagsTimestamp = ({ boxId, record, tag, updatedAt }: IProps) => {
  const tagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(boxId, boxId, tag),
  );

  if (record.isTableHeading) return null;

  const timeStamp = format(
    new Date(tagStatus?.updatedAt || updatedAt),
    'dd/MM/yyyy hh:mm',
  );
  return <div>{timeStamp === 'Invalid date' ? null : timeStamp}</div>;
};
