import { DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ConnectorStatusWithNumber } from '../../../components/connectors/ConnectorStatusWithNumber';
import { AuthorisationForcedIcon } from '../../../components/connectors/icons/connectorsTable/general/authorisation/AuthorisationForcedIcon';
import { AuthorisationSuccessIcon } from '../../../components/connectors/icons/connectorsTable/general/authorisation/AuthorisationSuccessIcon';
import { NoAuthorisationIcon } from '../../../components/connectors/icons/connectorsTable/general/authorisation/NoAuthorisationIcon';
import { EndSessionButtonWithModal } from '../../../components/sessions/EndSessionButtonWithModal';
import { appUrls } from '../../../config/url.constants';
import { UNIT_MESSAGES } from '../../../consts/unit.const';
import { useLazyGetSessionsCsvQuery } from '../../../store/api/sessions.api';
import { updateRequestedTablePage } from '../../../store/slices/tableMetaInfo.slice';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { StyledTable } from '../../../styled/table/StyledTable';
import { theme } from '../../../theme';
import { ISessionFilters } from '../../../types/filters.types';
import {
  ESessionConsumptionUnit,
  ISession,
  ISessionConnector,
  ISessionConsumption,
  ISessionInitiator,
  ISessionsResponse,
} from '../../../types/session.types';
import { Table } from '../../../types/table.types';
import { ConnectorTag } from '../../../types/tag.types';
import { IVessel } from '../../../types/vessel.types';
import { genericComponentByTagValue } from '../../../utils/connectors/genericComponentByTagValue';
import { durationInMonthsDaysHoursMinutesSeconds } from '../../../utils/shared/time/durationInMonthsDaysHoursMinutesSeconds';
import { secondsToHhMmSs } from '../../../utils/shared/time/secondsToHhMmSs';
import { secondsToYyyyMmDd } from '../../../utils/shared/time/secondsToYyyyMmDd';
import { formatNumber } from '../../../utils/shared/formats/formatNumbers';

enum SessionDataType {
  AUTHORISATION = 'authorisation',
  BOX = 'box',
  CONNECTOR = 'connector',
  CONSUMPTION = 'consumption',
  DURATION = 'duration',
  END_TIME = 'endedAt',
  INITIATOR = 'initiator',
  START_TIME = 'startedAt',
  TRANSACTION_ID = 'transactionId',
}

const MESSAGES = defineMessages({
  [SessionDataType.BOX]: {
    id: 'sessions_info_table.boxTableHeading',
    defaultMessage: 'Box',
  },
  [SessionDataType.AUTHORISATION]: {
    id: 'sessions_info_table.authorisationTableHeading',
    defaultMessage: 'Auth.',
  },
  [SessionDataType.CONNECTOR]: {
    id: 'sessions_info_table.connectorTableHeading',
    defaultMessage: 'Connector',
  },
  [SessionDataType.DURATION]: {
    id: 'sessions_info_table.durationTableHeading',
    defaultMessage: 'Duration',
  },
  [SessionDataType.END_TIME]: {
    id: 'sessions_info_table.endTimeTableHeading',
    defaultMessage: 'End time',
  },
  exportListToCsv: {
    id: 'sessions_info_table.exportListToCsv',
    defaultMessage: 'Export list to CSV',
  },
  [SessionDataType.INITIATOR]: {
    id: 'sessions_info_table.initiator',
    defaultMessage: 'Initiator',
  },
  [SessionDataType.START_TIME]: {
    id: 'sessions_info_table.startTimeTableHeading',
    defaultMessage: 'Start time',
  },
  [SessionDataType.TRANSACTION_ID]: {
    id: 'sessions_info_table.transactionIdTableHeading',
    defaultMessage: 'Transaction ID',
  },
  [SessionDataType.CONSUMPTION]: {
    id: 'sessions_info_table.usageTableHeading',
    defaultMessage: 'Usage',
  },
  eni: {
    id: 'sessions_info_table.eni',
    defaultMessage: 'E.N.I. number',
  },
  vesselName: {
    id: 'sessions_info_table.vesselName',
    defaultMessage: 'Vessel',
  },
});

const authorisationContentList = {
  0: <NoAuthorisationIcon mt="4px" />,
  1: <AuthorisationSuccessIcon mt="4px" />,
  2: <AuthorisationForcedIcon mt="4px" />,
};

interface IProps {
  sessionData: ISessionsResponse;
  requestedSessionFilters: ISessionFilters;
  boxId: string | undefined;
}

export const SessionsTable = ({
  boxId = undefined,
  requestedSessionFilters,
  sessionData,
}: IProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionList = sessionData.content;
  const [exportSessions] = useLazyGetSessionsCsvQuery();

  const columns: ColumnsType<ISession> = [
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.INITIATOR]),
      dataIndex: SessionDataType.INITIATOR,
      key: SessionDataType.INITIATOR,
      render: (initiator: ISessionInitiator) =>
        initiator ? `${initiator?.lastName} ${initiator?.firstName}` : '',
    },
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.BOX]),
      dataIndex: SessionDataType.CONNECTOR,
      key: SessionDataType.BOX,
      render: (connector: ISessionConnector) => connector.boxLabel,
    },
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.CONNECTOR]),
      dataIndex: SessionDataType.CONNECTOR,
      key: SessionDataType.CONNECTOR,
      render: (connector: ISessionConnector, session) => {
        const connectorWebStatusValue = connector.tagStatuses.find(
          (status) => status.tag === ConnectorTag.V1_WEB_STATUS,
        )?.value;

        const connectorSymbolStartValue = () => {
          if (!session.active) {
            return session.consumption.unit === ESessionConsumptionUnit.KWH
              ? '2'
              : '4';
          }
          return connector.tagStatuses.find(
            (status) => status.tag === ConnectorTag.V2_SYMBOL_START,
          )?.value;
        };

        return (
          <ConnectorStatusWithNumber
            active={session.active}
            connectorIndex={connector.index}
            connectorSymbolStartValue={connectorSymbolStartValue()}
            connectorWebStatusValue={connectorWebStatusValue}
          />
        );
      },
    },
    {
      title: intl.formatMessage(MESSAGES.eni),
      dataIndex: 'vessel',
      key: 'eniNumber',
      render: (vessel: IVessel) => vessel?.eniNumber,
    },
    {
      title: intl.formatMessage(MESSAGES.vesselName),
      dataIndex: 'vessel',
      key: 'vesselName',
      render: (vessel: IVessel) => vessel?.name,
    },
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.AUTHORISATION]),
      dataIndex: SessionDataType.CONNECTOR,
      key: SessionDataType.AUTHORISATION,
      render: (connector: ISessionConnector) =>
        genericComponentByTagValue({
          contentList: authorisationContentList,
          tagName: ConnectorTag.V2_SYMBOL_AUTORISATION,
          tags: connector.tagStatuses,
        }),
    },
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.START_TIME]),
      dataIndex: SessionDataType.START_TIME,
      key: SessionDataType.START_TIME,
      render: (startedAt: number) =>
        startedAt
          ? `${secondsToYyyyMmDd(startedAt)} - ${secondsToHhMmSs(startedAt)}`
          : '',
    },
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.END_TIME]),
      dataIndex: SessionDataType.END_TIME,
      key: SessionDataType.END_TIME,
      render: (endedAt: number) =>
        endedAt
          ? `${secondsToYyyyMmDd(endedAt)} - ${secondsToHhMmSs(endedAt)}`
          : '',
    },
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.DURATION]),
      key: SessionDataType.DURATION,
      render: (record: ISession) => {
        const duration = durationInMonthsDaysHoursMinutesSeconds(
          record.startedAt,
          record.endedAt,
        );

        return duration
          ? `${duration?.days}d ${duration?.hours}h ${duration?.minutes}m ${duration?.seconds}s`
          : '';
      },
    },
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.CONSUMPTION]),
      dataIndex: SessionDataType.CONSUMPTION,
      key: SessionDataType.CONSUMPTION,
      render: (consumption: ISessionConsumption) => {
        return `${formatNumber(consumption.total)} (${intl.formatMessage(
          UNIT_MESSAGES[consumption.unit],
        )})`;
      },
    },
    {
      title: intl.formatMessage(MESSAGES[SessionDataType.TRANSACTION_ID]),
      dataIndex: SessionDataType.TRANSACTION_ID,
      key: SessionDataType.TRANSACTION_ID,
    },
    {
      title: '',
      key: 'endSession',
      className: 'no-empty-cell-placeholder',
      render: (record: ISession) => (
        <>
          {!record.endedAt ? (
            <EndSessionButtonWithModal boxId={boxId} sessionId={record.id} />
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const handleChangePage = (requestedPage: number) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.SESSIONS_TABLE,
        value: requestedPage - 1,
      }),
    );
  };

  const handleRowClick = (sessionId: string) =>
    navigate(appUrls.sessions.detail(sessionId));

  return (
    <>
      {sessionData && (
        <>
          <StyledTable
            rowKey={(sessionRecord: ISession) => sessionRecord.id}
            onRow={(record: ISession) => ({
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onClick: (_) => handleRowClick(record.id),
            })}
            columns={columns}
            dataSource={sessionList}
            pagination={{
              current: sessionData.number + 1,
              onChange: (requestedPage: number) =>
                handleChangePage(requestedPage),
              pageSize: sessionData.size,
              position: ['bottomLeft'],
              total: sessionData.totalElements,
              showSizeChanger: false,
            }}
          />

          <StyledButton
            mr="auto"
            variant="secondary"
            mt={theme.spacing.small}
            data-testid="btn-export-sessions"
            onClick={() => exportSessions(requestedSessionFilters)}
          >
            <DownloadOutlined />
            {intl.formatMessage(MESSAGES.exportListToCsv)}
          </StyledButton>
        </>
      )}
    </>
  );
};
