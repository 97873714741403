import { formatNumber } from '../shared/formats/formatNumbers';

export const valuePermillage = (value: string | undefined) => {
  if (value) {
    const val = parseInt(value, 10);
    return formatNumber(val);
  }

  return undefined;
};
